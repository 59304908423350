<template>

  <div>
    <div class="container">

      <div class="row page-part">
        <div class="col-md-12 page-title">
          目标
        </div>
        <div class="col-md-12 page-body">
          
          <div v-if="!user">
            <div class="block page-timeline">
              <el-timeline>
                <el-timeline-item timestamp="请登录" placement="top">
                  <el-card>
                    <h4>登录后，才可以看到具体内容</h4>
                  </el-card>
                </el-timeline-item>
                
              </el-timeline>
            </div>
          </div>

          <div v-if="user">
            
            <div class="block page-timeline">
              <el-timeline>
                <!-- <el-timeline-item timestamp="2022年06月13日确立" placement="top">
                  <el-card>
                    <h4>创意源目标1：世界一流的编程试题库</h4>
                  </el-card>
                </el-timeline-item> -->
                <el-timeline-item timestamp="2022年06月13日确立" placement="top">
                  <el-card>
                    <h4>创意源目标1：世界一流的在线教育平台</h4>
                  </el-card>
                </el-timeline-item>
                <el-timeline-item timestamp="2022年06月13日确立" placement="top">
                  <el-card>
                    <h4>创意源目标2：世界一流的知识价值平台</h4>
                  </el-card>
                </el-timeline-item>
              </el-timeline>
            </div>
            
          </div>
          
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  props:['user'],
  data() {
    return {
      list: null,
    }
  },
  created() {
    // this.getList()
  },
  methods: {
    async getList() {     
      const response = await axios.post('/api/web/course/newList');
      const items = response.data.data.data
      this.list = items
      // console.log(items[0]['body'])
    },
    async course(id) {
      this.$router.push({path:`/course/${id}`})
    }
  }
}
</script>
